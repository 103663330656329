import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TextField, Box } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

export const Marktforschung = () => {
  const [formDataCompany, setFormDataCompany] = useState({
    company: "",
    name: "",
    help: "",
    phone: "",
    email: "",
    consent: "",
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDataCompany((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      // Basic form validation
      if (
        !formDataCompany.company ||
        !formDataCompany.name ||
        !formDataCompany.help ||
        !formDataCompany.phone ||
        !formDataCompany.email
      ) {
        setErrorMessage("Please fill out all required fields.");
        return;
      }

      const htmlBody = `
      <p><b>Unternehmen:</b> ${formDataCompany.company}</p>
      <p><b>Ich heiße:</b> ${formDataCompany.name}</p>
      <p><b>Ich brauche:</b> ${formDataCompany.help}</p>
      <p><b>Telefon:</b> ${formDataCompany.phone}</p>
      <p><b>Email:</b> ${formDataCompany.email}</p>
      <p><b>Consent:</b> ${formDataCompany.consent}</p>
    `;
      const response = await fetch("/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: "anfragen@mezu.de",
          subject: "Marktforschung FÜR UNTERNEHMEN",
          html: htmlBody,
        }),
      });

      if (response.ok) {
        setSuccessMessage("Email sent successfully!");
        setFormDataCompany({
          company: "",
          name: "",
          help: "",
          phone: "",
          email: "",
          consent: "",
        });
        window.grecaptcha.reset();
      } else {
        setErrorMessage("Failed to send email. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Error sending email. Please try again.");
      console.error("Error sending email:", error.message);
    } finally {
      setLoading(false);
    }
  };
  // for jobs seekers

  const [formDataSeekers, setFormDataSeekers] = useState({
    name: "",
    phone: "",
    email: "",
    country: "",
    place: "",
    area: "",
    sonstiges: "",
    speaking: "",
    writing: "",
    consent: "",
  });

  const [load, setLoad] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleSeekersInputChange = (e) => {
    const { name, value } = e.target;
    setFormDataSeekers((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSeekersSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoad(true);

      // Basic form validation
      if (
        !formDataSeekers.name ||
        !formDataSeekers.phone ||
        !formDataSeekers.email ||
        !formDataSeekers.country ||
        !formDataSeekers.place ||
        !formDataSeekers.area ||
        !formDataSeekers.speaking ||
        !formDataSeekers.writing
      ) {
        setErrorMsg("Please fill out all required fields.");
        return;
      }
      const htmlBody = `
      <p><b>Ich heiße:</b> ${formDataSeekers.name}</p>
      <p><b>Telefon:</b> ${formDataSeekers.phone}</p>
      <p><b>Email:</b> ${formDataSeekers.email}</p>
      <p><b>In welchem Land wohnst Du:</b> ${formDataSeekers.country}</p>
      <p><b>Möchtest Du im Büro oder im Homeoffice arbeiten:</b> ${formDataSeekers.place}</p>
      <p><b>In welchem Bereich möchtest Du arbeiten:</b> ${formDataSeekers.area}</p>
      <p><b>Sonstiges:</b> ${formDataSeekers.sonstiges}</p>
      <p><b>Wie gut sind Deine deutschen Sprachkenntnisse beim Sprechen:</b> ${formDataSeekers.speaking}</p>
      <p><b>Wie gut sind Deine deutschen Sprachkenntnisse beim Schreiben:</b> ${formDataSeekers.writing}</p>
    `;
      const response = await fetch("/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: "jobs@mezu.de",
          subject: "Contactcenter FÜR ARBEITSSUCHENDE",
          html: htmlBody,
        }),
      });

      if (response.ok) {
        setSuccessMsg("Email sent successfully!");
        setFormDataSeekers({
          name: "",
          phone: "",
          email: "",
          country: "",
          place: "",
          area: "",
          sonstiges: "",
          speaking: "",
          writing: "",
          consent: "",
        });
        window.grecaptcha.reset();
      } else {
        setErrorMsg("Failed to send email. Please try again.");
      }
    } catch (error) {
      setErrorMsg("Error sending email. Please try again.");
      console.error("Error sending email:", error.message);
    } finally {
      setLoad(false);
    }
  };

  return (
    <div>
      <header id="header">
        <div className="intro-etude">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intern-intro-text">
                  <h1>
                    Marktforschung
                    <span></span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div>
        <div id="about">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                {" "}
                <img
                  src="img/etude1.jpg"
                  className="img-responsive"
                  alt=""
                />{" "}
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="about-text">
                  <h2>Für Unternehmen</h2>
                  <p>
                    Sie haben für eine aktuelle Umfrage einen Personalengpass
                    oder wollen langfristig Kosten senken oder einen weiteren
                    Dienstleister dazu gewinnen?
                  </p>
                  <p>
                    Testen Sie uns. Wir stellen Ihnen innerhalb von drei Tagen
                    fünf Mitarbeiterinnen und Mitarbeiter und innerhalb von vier
                    Wochen auch 100 Mitarbeiterinnen und Mitarbeiter zur
                    Verfügung.
                  </p>
                  <p>
                    Wir verfügen über zuverlässige Mitarbeiterinnen und Mitarbeiter innerhalb der Europäsischen Union (z.B. in Bulgarien) als auch außerhalb (z.B. im Kosovo, in Nordmazedonien oder in Tunesien).

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <Box className="form-box" sx={{ p: 2 }}>
                <form onSubmit={handleSubmit}>
                  <p className="form_label">Wie heißt Ihr Unternehmen?</p>
                  <TextField
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    fullWidth
                    name="company"
                    value={formDataCompany.company}
                    onChange={handleInputChange}
                  />
                  <p className="form_label">Wie heißen Sie?</p>
                  <TextField
                    fullWidth
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    name="name"
                    value={formDataCompany.name}
                    onChange={handleInputChange}
                  />
                  <p className="form_label">Wie können wir Ihnen helfen?</p>
                  <TextField
                    fullWidth
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    name="help"
                    value={formDataCompany.help}
                    onChange={handleInputChange}
                  />
                  <p className="form_label">Wie können wir Sie telefonisch erreichen?</p>
                  <TextField
                    fullWidth
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    name="phone"
                    value={formDataCompany.phone}
                    onChange={handleInputChange}
                  />
                  <p className="form_label">Wie können wir Sie per Mail erreichen?</p>
                  <TextField
                    fullWidth
                    type={"email"}
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    name="email"
                    value={formDataCompany.email}
                    onChange={handleInputChange}
                  />

                  <RadioGroup
                  className="daten-check"
                    row
                    required
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="consent"
                    value={formDataCompany.consent}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel
                      value="Ja"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label={<span>Ich bin davon einverstanden, dass meine persönlichen Daten auch an <a href="/partnernetzwerk">unser Partnernetzwerk</a>, Arbeitgeber und Auftraggeber weitergeleitet werden können ausschließlich für die Beantwortung des Anliegens bzw. zu Zwecken der Auftragsvermittlung. Jegliche Verwendung meiner persönlichen Daten durch Dritte für sonstige Marketingzwecke ist ausgeschlossen.</span>}
                    />
                  </RadioGroup>
                  <div className="captcha_container">
                <ReCAPTCHA
                  sitekey="6LfbQJEpAAAAAP2uTj5sdv5bYMmex1NMqY7pbsjy"
                  hl="de"
                />
                </div>
                  {loading && <p>Loading...</p>}
                  {successMessage && (
                    <p style={{ color: "green" }}>{successMessage}</p>
                  )}
                  {errorMessage && (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  )}
                  <button className="submit-form-btn" type="submit">
                    ANFRAGE ABSCHICKEN
                  </button>
                </form>
              </Box>
            </div>
          </div>
        </div>
        <div id="about">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <div className="about-text">
                  <h2>Für Arbeitssuchende</h2>
                  <p>
                    Du suchst Arbeit? Gerne sind wir Dir bei der Jobsuche in der
                    Marktforschung behilflich.
                  </p>
                  <p>Du verfügst mindestens über Deutschkenntnisse im Niveau B1, kannst Zahlen lesen und verstehen und Texte gut vorlesen. Durch die regelmäßige Beschäftigung mit der deutschen Sprache hast Du Aufstiegsmöglichkeiten in anspruchsvollere Marktforschungsprojekte bzw. attraktive Inboundprojekte verschiedener Auftraggeber.</p>
                </div>
              </div>
              <div className="col-xs-12 col-md-6">
                {" "}
                <img
                  src="img/callcenter3.jpg"
                  className="img-responsive"
                  alt=""
                />{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <h3 style={{ textAlign: "center" }}>
                Hier unverbindlich und kostenlos bewerben
              </h3>
              <Box className="form-box" sx={{ p: 2 }}>
                <form onSubmit={handleSeekersSubmit}>
                  <p className="form_label">Wie heißt Du?</p>
                  <TextField
                    fullWidth
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    name="name"
                    value={formDataSeekers.name}
                    onChange={handleSeekersInputChange}
                  />

                  <p className="form_label">Wie können wir Dich telefonisch erreichen?</p>
                  <TextField
                    fullWidth
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    name="phone"
                    value={formDataSeekers.phone}
                    onChange={handleSeekersInputChange}
                  />
                  <p className="form_label">Wie können wir Dich per Mail erreichen?</p>
                  <TextField
                    fullWidth
                    type={"email"}
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    name="email"
                    value={formDataSeekers.email}
                    onChange={handleSeekersInputChange}
                  />
                  <p className="form_label">In welchem Land wohnst Du?</p>
                  <TextField
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    fullWidth
                    name="country"
                    value={formDataSeekers.country}
                    onChange={handleSeekersInputChange}
                  />
                  <p className="form_label">Möchtest Du im Büro oder im Homeoffice arbeiten? </p>
                  <RadioGroup
                    row
                    required
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="place"
                    value={formDataSeekers.place}
                    onChange={handleSeekersInputChange}
                  >
                    <FormControlLabel
                      value="Büro "
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="Büro "
                    />
                    <FormControlLabel
                      value="Homeoffice "
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="Homeoffice "
                    />
                  </RadioGroup>
                  <p className="form_label">In welchem Bereich möchtest Du arbeiten?</p>
                  <RadioGroup
                    row
                    required
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="area"
                    value={formDataSeekers.area}
                    onChange={handleSeekersInputChange}
                  >
                    <FormControlLabel
                      value="Buchhaltung  "
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="Buchhaltung  "
                    />
                    <FormControlLabel
                      value="Callcenter  "
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="Callcenter  "
                    />
                    <FormControlLabel
                      value="Marktforschung   "
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="Marktforschung   "
                    />
                    <FormControlLabel
                      value="Reisebüro   "
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="Reisebüro   "
                    />
                    <FormControlLabel
                      value="Sonstiges"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="Sonstiges    "
                    />
                  </RadioGroup>
                  {formDataSeekers.area === "Sonstiges" && (
                    <TextField
                      fullWidth
                      inputProps={{ style: { fontSize: 15, padding: 10 } }}
                      name="sonstiges"
                      value={formDataSeekers.sonstiges}
                      onChange={handleSeekersInputChange}
                    />
                  )}
                  <p className="form_label">
                    Wie gut sind Deine deutschen Sprachkenntnisse beim Sprechen?
                  </p>
                  <RadioGroup
                    row
                    required
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="speaking"
                    value={formDataSeekers.speaking}
                    onChange={handleSeekersInputChange}
                  >
                    <FormControlLabel
                      value="A1"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="A1"
                    />
                    <FormControlLabel
                      value="A2"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="A2"
                    />
                    <FormControlLabel
                      value="B1"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="B1"
                    />
                    <FormControlLabel
                      value="B2"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="B2"
                    />
                    <FormControlLabel
                      value="C1"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="C1"
                    />
                    <FormControlLabel
                      value="C2"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="C2"
                    />
                    <FormControlLabel
                      value="Muttersprache"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="Muttersprache"
                    />
                  </RadioGroup>
                  <p className="form_label">
                    Wie gut sind Deine deutschen Sprachkenntnisse beim Schreiben?
                  </p>
                  <RadioGroup
                    row
                    required
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="writing"
                    value={formDataSeekers.writing}
                    onChange={handleSeekersInputChange}
                  >
                    <FormControlLabel
                      value="A1"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="A1"
                    />
                    <FormControlLabel
                      value="A2"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="A2"
                    />
                    <FormControlLabel
                      value="B1"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="B1"
                    />
                    <FormControlLabel
                      value="B2"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="B2"
                    />
                    <FormControlLabel
                      value="C1"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="C1"
                    />
                    <FormControlLabel
                      value="C2"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="C2"
                    />
                    <FormControlLabel
                      value="Muttersprache"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="Muttersprache"
                    />
                  </RadioGroup>

                  <RadioGroup
                  className="daten-check"
                    row
                    required
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="consent"
                    value={formDataSeekers.consent}
                    onChange={handleSeekersInputChange}
                  >
                    <FormControlLabel
                      value="Ja"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label={<span> Ich bin davon einverstanden, dass meine persönlichen Daten an <a href="/partnernetzwerk">unser Partnernetzwerk</a>, Arbeitgeber und Auftraggeber weitergeleitet werden können ausschließlich für die Beantwortung des Anliegens bzw. zu Zwecken der Jobvermittlung. </span>}
                    />
                  </RadioGroup>
                  <div className="captcha_container">
                <ReCAPTCHA
                  sitekey="6LfbQJEpAAAAAP2uTj5sdv5bYMmex1NMqY7pbsjy"
                  hl="de"
                />
                </div>
                  {load && <p>Loading...</p>}
                  {successMsg && <p style={{ color: "green" }}>{successMsg}</p>}
                  {errorMsg && <p style={{ color: "red" }}>{errorMsg}</p>}
                  <button
                    className="submit-form-btn"
                    variant="contained"
                    type="submit"
                  >
                    ANFRAGE ABSCHICKEN
                  </button>
                </form>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
