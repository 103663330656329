import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  FooterContainer,
  Row,
  Column,
  Heading,
  FooterPara,
} from "../data/FooterStyles";
export const Contact = (props) => {
  return (
    <div className="page_footer">
      <Box>
        <FooterContainer>
          <Row>
            <Column style={{justifyContent:"center",marginLeft:"0px"}}>
            <Link className="footer-logo" to="/" style={{ color: 'white' }}>
            <img
                  src="img/mezu-textlogo-white.png"
                  className="img-responsive"
                  alt=""
                />
          </Link>{" "}
            </Column>
            <Column>
            <Heading className="footer_heading">Dienstleistungen</Heading>
              <Link className="footer-links" to='/buchhaltung'>Buchhaltung</Link>
              <Link className="footer-links" to='/contactcenter'>Contactcenter</Link>
              <Link className="footer-links" to='/firmengruendung'>Firmengründung</Link>
              <Link className="footer-links" to="/it">IT</Link>
              <Link className="footer-links" to="/jobs">Jobs</Link>
              <Link className="footer-links" to="/marktforschung">Marktforschung</Link>
              <Link className="footer-links" to="/personalvermittlung">Personalvermittlung</Link>
              <Link className="footer-links" to="/reisen">Reisen</Link>
            </Column>
            <Column>
              <Heading className="footer_heading">Weitere Links</Heading>
              <Link className="footer-links" to="/datenschutz">Datenschutz</Link>
              <Link className="footer-links" to="/impressum">Impressum</Link>
              <Link className="footer-links" to="/kontakt">Kontakt</Link>
              <Link className="footer-links" to="/partnernetzwerk">Partnernetzwerk</Link>
            </Column>
            <Column>
              <div className="contact-item">
                <Heading className="footer_heading">Kontaktinformation</Heading>
                <FooterPara>
                  <span>
                    <i className="fa fa-map-marker"></i>{" "}
                  </span>
                  mezu GmbH <br/>Domplatz 40, 48143 Münster
                </FooterPara>
              </div>
              <div className="contact-item">
                <FooterPara>
                  <span>
                    <i className="fa fa-phone"></i>
                  </span>{" "}
                  {props.data ? props.data.phone : "loading"}
                </FooterPara>
              </div>
              <div className="contact-item">
                <FooterPara>
                  <span>
                    <i className="fa fa-envelope-o"></i>
                  </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </FooterPara>
              </div>
            </Column>
          </Row>
        </FooterContainer>
        <div>
          <div className="social">
            <ul>
              <li>
                <a href={props.data ? props.data.facebook : "/"} target="_blank" rel="noreferrer">
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href={props.data ? props.data.linkedin : "/"} target="_blank" rel="noreferrer">
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a href={props.data ? props.data.instagram : "/"} target="_blank" rel="noreferrer">
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div id="footer">
        <div className="container text-center">
          <p>&copy; 2024 mezu</p>
        </div>
      </div>
      </Box>
      
      {/* <div id="contact">
        <div className="container">
          <div className="col-md-3">
            <div className="row">
            <a className="navbar-brand page-scroll" href="#page-top">
            Mezu
          </a>{" "}
            
            </div>
          </div>
          <div className="col-md-3 ">
            <div className="row">
              <div className="widget widget_nav_menu">
            <ul >
            <li className={location.pathname === "/" ? "active" : ""}>
              <Link to="/">Home</Link>
            </li>
            <li className={location.pathname === "/buchhaltung" ? "active" : ""}>
              <Link to="/buchhaltung">Buchhaltung</Link>
            </li>
            <li className={location.pathname === "/contactcenter" ? "active" : ""}>
              <Link to="/contactcenter">Contactcenter</Link>
            </li>
            <li className={location.pathname === "/firmengrundung" ? "active" : ""}>
              <Link to="/firmengrundung">Firmengründung</Link>
            </li>
            <li className={location.pathname === "/it" ? "active" : ""}>
              <Link to="/it">IT</Link>
            </li>
            <li className={location.pathname === "/jobs" ? "active" : ""}>
              <Link to="/jobs">Jobs</Link>
            </li>
            <li className={location.pathname === "/marktforschung" ? "active" : ""}>
              <Link to="/marktforschung">Marktforschung</Link>
            </li>
            <li className={location.pathname === "/personalvermittlung" ? "active" : ""}>
              <Link to="/personalvermittlung">Personalvermittlung</Link>
            </li>
            <li className={location.pathname === "/reisen" ? "active" : ""}>
              <Link to="/reisen">Reisen</Link>
            </li>
          </ul>
            </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="row">
             
            
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Kontaktinformation</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024  Design by{" "}
            Rami Gharbi
          </p>
        </div>
      </div> */}
    </div>
  );
};
