import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export const Navigation = () => {
  const location = useLocation();
  const [navbarBackground, setNavbarBackground] = useState("transparent");
  const [linkColor, setLinkColor] = useState("white");
  const [introShadow, setIntroShadow] = useState("none");
  const [logoColor, setLogoColor] = useState('img/mezu-textlogo-white.png')
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 0) {
      // Scrolled, set background to white
      setNavbarBackground("#ffffff");
      setLinkColor("#555");
      setIntroShadow("0 0 10px rgba(0, 0, 0, 0.15)");
      setLogoColor('img/mezu-textlogo-black.png')
    } else {
      // At the top, set background to transparent
      setNavbarBackground("transparent");
      setLinkColor("#ffffff");
      setIntroShadow("none");
      setLogoColor('img/mezu-textlogo-white.png')
    }
  };

  useEffect(() => {
    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <nav
      id="menu"
      className="navbar navbar-default navbar-fixed-top"
      style={{ backgroundColor: navbarBackground, boxShadow: introShadow }}
    >
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <Link className="navbar-brand" to="/" style={{ color: linkColor }}>
            <img src={logoColor} alt="mezu" className="mezu_logo" />
          </Link>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li className={location.pathname === "/" ? "active" : ""}>
              <Link to="/" style={{ color: linkColor }}>
                Home
              </Link>
            </li>
            <li
              className={location.pathname === "/buchhaltung" ? "active" : ""}
            >
              <Link to="/buchhaltung" style={{ color: linkColor }}>
                Buchhaltung
              </Link>
            </li>
            <li
              className={location.pathname === "/contactcenter" ? "active" : ""}
            >
              <Link to="/contactcenter" style={{ color: linkColor }}>
                Contactcenter
              </Link>
            </li>
            <li
              className={
                location.pathname === "/firmengruendung" ? "active" : ""
              }
            >
              <Link to="/firmengruendung" style={{ color: linkColor }}>
                Firmengründung
              </Link>
            </li>
            <li className={location.pathname === "/it" ? "active" : ""}>
              <Link to="/it" style={{ color: linkColor }}>
                IT
              </Link>
            </li>
            <li className={location.pathname === "/jobs" ? "active" : ""}>
              <Link to="/jobs" style={{ color: linkColor }}>
                Jobs
              </Link>
            </li>
            <li
              className={
                location.pathname === "/marktforschung" ? "active" : ""
              }
            >
              <Link to="/marktforschung" style={{ color: linkColor }}>
                Marktforschung
              </Link>
            </li>
            <li
              className={
                location.pathname === "/personalvermittlung" ? "active" : ""
              }
            >
              <Link to="/personalvermittlung" style={{ color: linkColor }}>
                Personalvermittlung
              </Link>
            </li>
            <li className={location.pathname === "/reisen" ? "active" : ""}>
              <Link to="/reisen" style={{ color: linkColor }}>
                Reisen
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
