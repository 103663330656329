import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TextField, Box } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

export const Firmengruendung = () => {
  const [formData, setFormData] = useState({
    company: "",
    name: "",
    help: "",
    phone: "",
    email: "",
    consent: "",
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      // Basic form validation
      if (
        !formData.company ||
        !formData.name ||
        !formData.help ||
        !formData.phone ||
        !formData.email
      ) {
        setErrorMessage("Please fill out all required fields.");
        return;
      }

      const htmlBody = `
      <p><b>Unternehmen:</b> ${formData.company}</p>
      <p><b>Ich heiße:</b> ${formData.name}</p>
      <p><b>Ich brauche:</b> ${formData.help}</p>
      <p><b>Telefon:</b> ${formData.phone}</p>
      <p><b>Email:</b> ${formData.email}</p>
      <p><b>Consent:</b> ${formData.consent}</p>
    `;
      const response = await fetch("/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: "anfragen@mezu.de",
          subject: "Firmengründung",
          html: htmlBody,
        }),
      });

      if (response.ok) {
        setSuccessMessage("Email sent successfully!");
        setFormData({
          company: "",
          name: "",
          help: "",
          phone: "",
          email: "",
          consent: "",
        });
        window.grecaptcha.reset();
      } else {
        setErrorMessage("Failed to send email. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Error sending email. Please try again.");
      console.error("Error sending email:", error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <header id="header">
        <div className="intro-entreprise">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intern-intro-text">
                  <h1>
                    Firmengründung
                    <span></span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div>
        <div id="about">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                {" "}
                <img
                  src="img/entreprise1.jpg"
                  className="img-responsive"
                  alt=""
                />{" "}
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="about-text">
                  <h2>Firmengründung</h2>
                  <p>
                  Wir haben in den letzten 30 Jahren Firmen in vielen Ländern gegründet und betreut. Wir haben Erfahrungen gesammelt und ein umfangreiches Partnernetzwerk aufgebaut:
                  </p>
                  <div className="list-style">
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                      
                      <ul>
                        <li>Argentinien</li>
                        <li>Brasilien</li>
                        <li>Bulgarien</li>
                        <li>China</li>
                      </ul>
                    </div>
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                      
                      <ul>
                        <li>Deutschland</li>
                        <li>Frankreich</li>
                        <li>Griechenland</li>
                        <li>Hong Kong</li>
                      </ul>
                    </div>
                    <div className="col-lg-4 col-sm-4 col-xs-12">
                      
                      <ul>
                        <li>Kosovo</li>
                        <li>Spanien</li>
                        <li>Tunesien</li>
                        <li>Türkei</li>
                        
                      </ul>
                    </div>
                  </div>
                  <p>
                    Gerne sind wir Ihnen bei der Gründung, Leitung und
                    Mitarbeitersuche einer neuen Firma im Ausland behilflich.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <h3 style={{ textAlign: "center" }}>
                Bitte schildern Sie uns unverbindlich Ihren Bedarf, wir melden
                uns umgehend bei Ihnen.
              </h3>
              <Box className="form-box" sx={{ p: 2 }}>
                <form onSubmit={handleSubmit}>
                  <p className="form_label">Wie heißt Ihr Unternehmen?</p>
                  <TextField
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    fullWidth
                    required
                    name="company"
                    value={formData.company}
                    onChange={handleInputChange}
                  />
                  <p className="form_label">Wie heißen Sie?</p>
                  <TextField
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    fullWidth
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                  />
                  <p className="form_label">Wie können wir Ihnen helfen?</p>
                  <TextField
                    name="help"
                    value={formData.help}
                    onChange={handleInputChange}
                    required
                    fullWidth
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                  />
                  <p className="form_label">Wie können wir Sie telefonisch erreichen?</p>
                  <TextField
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                    fullWidth
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                  />
                  <p className="form_label">Wie können wir Sie per Mail erreichen?</p>
                  <TextField
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    fullWidth
                    type={"email"}
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                  />

                  <RadioGroup
                  className="daten-check"
                    row
                    required
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="consent"
                    value={formData.consent}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel
                      value="Ja"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label={<span>Ich bin davon einverstanden, dass meine persönlichen Daten auch an <a href="/partnernetzwerk">unser Partnernetzwerk</a>, Arbeitgeber und Auftraggeber weitergeleitet werden können ausschließlich für die Beantwortung des Anliegens bzw. zu Zwecken der Auftragsvermittlung. Jegliche Verwendung meiner persönlichen Daten durch Dritte für sonstige Marketingzwecke ist ausgeschlossen.</span>}
                    />
                  </RadioGroup>
                  <div className="captcha_container">
                <ReCAPTCHA
                  sitekey="6LfbQJEpAAAAAP2uTj5sdv5bYMmex1NMqY7pbsjy"
                  hl="de"
                />
                </div>
                  {loading && <p>Loading...</p>}
                  {successMessage && (
                    <p style={{ color: "green" }}>{successMessage}</p>
                  )}
                  {errorMessage && (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  )}
                  <button
                    className="submit-form-btn"
                    variant="contained"
                    type="submit"
                  >
                    ANFRAGE ABSCHICKEN
                  </button>
                </form>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
