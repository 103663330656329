import React from "react";

export const Partnernetzwerk = () => {
  return (
    <div>
        <header id="header">
        <div className="intro-callcenter">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intern-intro-text">
                  <h1>
                  Partnernetzwerk
                    <span></span>
                  </h1>

                  {/* <a
                    href="#features"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Learn More
                  </a>{" "} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div id="about">
        <div className="about-text">
      <div className="container">
        <div className="row">
          <div className="list-style">
            <ul>
              <li><a href="/reisen">Gruppenreisen Touristik GmbH </a></li>
            </ul>
          </div>
          <p>Die Gruppenreisen Touristik GmbH ist spezialisiert auf die Durchführung von Betriebsausflügen, Konzertreisen, Vereinsreisen etc.</p>
          <div className="list-style">
            <ul>
              <li><a href="/reisen">KFF Tourismus GmbH</a></li>
            </ul>
          </div>
          <p>Die KFF Tourismus GmbH ist spezialisiert auf die Durchführung von Gruppenflügen und Gruppenreisen.</p>
          <div className="list-style">
            <ul>
              <li><a href="/reisen">Swinging Agents France S.A.S</a></li>
            </ul>
          </div>
          <p>Die Swinging Agents France S.A.S. bearbeitet Gruppenreisen und individuelle Reisebuchungen in Frankreich.</p>
          <div className="list-style">
            <ul>
              <li><a href="/contactcenter">Swinging Agents GmbH </a></li>
            </ul>
          </div>
          <p>Die Swinging Agents GmbH arbeitet als Contactcenter mit Tochterunternehmen im Kosovo, in Tunesien und in der Türkei für viele namhafte Unternehmen insbesondere in der Reisebranche.</p>
           
          <div className="list-style">
            <ul>
              <li><a href="/contactcenter">Smart Voice Connect EOOD </a></li>
            </ul>
          </div>
          <p>Die Smart Voice Connect EOOD arbeitet als Contactcenter in Bulgarien für ein Unternehmen in der Mieterkommunikation.</p>
          <div className="list-style">
            <ul>
              <li><a href="/contactcenter">Smart Voice Connect GmbH  </a></li>
            </ul>
          </div>
          <p>Die Smart Voice Connect GmbH arbeitet als Contactcenter in Deutschland. </p>

        </div>
      </div>
      </div>
      </div>
    </div>
  );
};
