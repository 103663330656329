
import styled from "styled-components";

export const Box = styled.div`
	padding: 3% 2% 0% 2%;
	background: black;
	width: 100%;
    position: relative;
    overflow: hidden;
	z-index: 1;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 18%;
        height: 73%;
        background: url('../img/bg_footer.png') no-repeat;
        background-size: cover;
        z-index: 1;
		box-shadow: inset 0px 0px 80px 80px rgba(0, 0, 0, 1);
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }


	@media (max-width: 1000px) {
		// padding: 70px 30px;
	}
`;
export const FooterContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1200px;
	margin: 0 auto;
	position:relative;
	z-index:2;
	/* background: red; */
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	margin-left: 30px;
`;

export const Row = styled.div`
	display: grid;
	grid-template-columns: repeat(
		auto-fill,
		minmax(250px, 1fr)
	);
	grid-gap: 20px;

	@media (max-width: 1000px) {
		grid-template-columns: repeat(
			auto-fill,
			minmax(200px, 1fr)
		);
	}
`;

export const FooterLink = styled.a`
	color: #fff;
	margin-bottom: 20px;
	font-size: 18px;
	text-decoration: none;

	&:hover {
		color: red;
		transition: 200ms ease-in;
	}
`;
export const FooterPara = styled.p`
	color: #fff;
	margin-bottom: 20px;
	font-size: 16px;
	
`;
export const Heading = styled.p`
	font-size: 24px;
	color: #fff;
	margin-bottom: 40px;
	font-weight: bold;
`;
