import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TextField, Box } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

export const Jobs = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    live: "",
    country: "",
    move: "",
    place: "",
    area: "",
    sonstiges: "",
    speaking: "",
    writing: "",
    consent: "",
  });

  const [load, setLoad] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleSeekersInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSeekersSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoad(true);

      // Basic form validation
      if (
        !formData.name ||
        !formData.phone ||
        !formData.email ||
        !formData.live ||
        !formData.move ||
        !formData.sonstiges ||
        !formData.speaking ||
        !formData.writing
      ) {
        setErrorMsg("Please fill out all required fields.");
        return;
      }
      const htmlBody = `
          <p><b>Ich heiße:</b> ${formData.name}</p>
      <p><b>Telefon:</b> ${formData.phone}</p>
      <p><b>Email:</b> ${formData.email}</p>
          <p><b>Wohnst Du in Deutschland?</b> ${formData.live}</p>
          <p><b>In welchem Land wohnst Du?</b> ${formData.country}</p>
          <p><b>Möchtest Du nach Deutschland umziehen?</b> ${formData.move}</p>
          <p><b>Möchtest Du im Büro oder im Homeoffice arbeiten?</b> ${formData.place}</p>
          <p><b>In welchem Bereich möchtest Du arbeiten?</b> ${formData.area}</p>
          <p><b>Bitte nenne den Bereich:</b> ${formData.sonstiges}</p>
          <p><b>Wie gut sind Deine deutschen Sprachkenntnisse beim Sprechen:</b> ${formData.speaking}</p>
          <p><b>Wie gut sind Deine deutschen Sprachkenntnisse beim Schreiben:</b> ${formData.writing}</p>
        `;
      const response = await fetch("/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: "jobs@mezu.de",
          subject: "Jobs",
          html: htmlBody,
        }),
      });

      if (response.ok) {
        setSuccessMsg("Email sent successfully!");
        setFormData({
          name: "",
          phone: "",
          email: "",
          live: "",
          country: "",
          move: "",
          place: "",
          area: "",
          sonstiges: "",
          speaking: "",
          writing: "",
          consent: "",
        });
        window.grecaptcha.reset();
      } else {
        setErrorMsg("Failed to send email. Please try again.");
      }
    } catch (error) {
      setErrorMsg("Error sending email. Please try again.");
      console.error("Error sending email:", error.message);
    } finally {
      setLoad(false);
    }
  };
  return (
    <div>
      <header id="header">
        <div className="intro-jobs">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intern-intro-text">
                  <h1>
                    Jobs
                    <span></span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div>
        <div id="about">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <div className="about-text">
                  <h2>Jobs</h2>
                  <p>
                    Wir und unsere Partnerunternehmen beschäftigen aktuell
                    Menschen in Argentinien, Brasilien, China, Deutschland,
                    Frankreich, Griechenland, Kosovo, Philippinen, Tunesien und
                    der Türkei.
                  </p>

                  <div className="list-style">
                    <div className="col-lg-6 col-sm-6 col-xs-12">
                      <h5>Du wohnst in Deutschland </h5>
                      <ul>
                        <li>suchst Arbeit in Deutschland?</li>
                        <li>suchst Arbeit im Homeoffice?</li>
                        <li>suchst Arbeit im Ausland?</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-xs-12">
                      <h5>Du wohnst im Ausland </h5>
                      <ul>
                        <li>suchst Arbeit im Homeoffice?</li>
                        <li>suchst Arbeit im Ausland?</li>
                        <li>suchst Arbeit in Deutschland?</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6">
                {" "}
                <img
                  src="img/jobs1.jpg"
                  className="img-responsive"
                  alt=""
                />{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <h3 style={{ textAlign: "center" }}>
                Hier unverbindlich und kostenlos bewerben
              </h3>
              <Box className="form-box" sx={{ p: 2 }}>
                <form onSubmit={handleSeekersSubmit}>
                  <p className="form_label">Wie heißt Du?</p>
                  <TextField
                    fullWidth
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    name="name"
                    value={formData.name}
                    onChange={handleSeekersInputChange}
                  />

                  <p className="form_label">Wie können wir Dich telefonisch erreichen?</p>
                  <TextField
                    fullWidth
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    name="phone"
                    value={formData.phone}
                    onChange={handleSeekersInputChange}
                  />
                  <p className="form_label">Wie können wir Dich per Mail erreichen?</p>
                  <TextField
                    fullWidth
                    type={"email"}
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    name="email"
                    value={formData.email}
                    onChange={handleSeekersInputChange}
                  />
                  <p className="form_label">Wohnst Du in Deutschland? </p>
                  <RadioGroup
                    row
                    required
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="live"
                    value={formData.live}
                    onChange={handleSeekersInputChange}
                  >
                    <FormControlLabel
                      value="ja"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="ja"
                    />
                    <FormControlLabel
                      value="nein"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="nein"
                    />
                  </RadioGroup>
                  {formData.live === "nein" && (
                    <div>
                      <p className="form_label">In welchem Land wohnst Du? </p>
                      <TextField
                        fullWidth
                        inputProps={{ style: { fontSize: 15, padding: 10 } }}
                        name="country"
                        value={formData.country}
                        onChange={handleSeekersInputChange}
                      />
                    </div>
                  )}

                  <p className="form_label">Möchtest Du nach Deutschland umziehen? </p>
                  <RadioGroup
                    row
                    required
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="move"
                    value={formData.move}
                    onChange={handleSeekersInputChange}
                  >
                    <FormControlLabel
                      value="ja"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="ja"
                    />
                    <FormControlLabel
                      value="nein"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="nein"
                    />
                    <FormControlLabel
                      value="vielleicht"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="vielleicht"
                    />
                  </RadioGroup>
                  {formData.move === "ja" && (
                    <div>
                      <p className="form_label">Möchtest Du im Büro oder im Homeoffice arbeiten? </p>
                      <RadioGroup
                        row
                        required
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="place"
                        value={formData.place}
                        onChange={handleSeekersInputChange}
                      >
                        <FormControlLabel
                          value="Büro"
                          InputLabelProps={{ style: { fontSize: 15 } }}
                          control={<Radio />}
                          label="Büro"
                        />
                        <FormControlLabel
                          value="Homeoffice"
                          InputLabelProps={{ style: { fontSize: 15 } }}
                          control={<Radio />}
                          label="Homeoffice"
                        />
                      </RadioGroup>
                    </div>
                  )}
                  <p className="form_label">In welchem Bereich möchtest Du arbeiten?</p>
                  <RadioGroup
                    row
                    required
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="area"
                    value={formData.area}
                    onChange={handleSeekersInputChange}
                  >
                    <FormControlLabel
                      value="Buchhaltung  "
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="Buchhaltung  "
                    />
                    <FormControlLabel
                      value="Callcenter  "
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="Callcenter  "
                    />
                    <FormControlLabel
                      value="Marktforschung   "
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="Marktforschung   "
                    />
                    <FormControlLabel
                      value="Reisebüro   "
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="Reisebüro   "
                    />
                    <FormControlLabel
                      value="Sonstiges"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="Sonstiges    "
                    />
                  </RadioGroup>
                  {formData.area === "Sonstiges" && (
                    <div>
                      <p className="form_label">Bitte nenne den Bereich:</p>
                      <TextField
                        fullWidth
                        inputProps={{ style: { fontSize: 15, padding: 10 } }}
                        name="sonstiges"
                        value={formData.sonstiges}
                        onChange={handleSeekersInputChange}
                      />
                    </div>
                  )}
                  <p className="form_label">
                    Wie gut sind Deine deutschen Sprachkenntnisse beim Sprechen?
                  </p>
                  <RadioGroup
                    row
                    required
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="speaking"
                    value={formData.speaking}
                    onChange={handleSeekersInputChange}
                  >
                    <FormControlLabel
                      value="A1"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="A1"
                    />
                    <FormControlLabel
                      value="A2"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="A2"
                    />
                    <FormControlLabel
                      value="B1"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="B1"
                    />
                    <FormControlLabel
                      value="B2"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="B2"
                    />
                    <FormControlLabel
                      value="C1"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="C1"
                    />
                    <FormControlLabel
                      value="C2"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="C2"
                    />
                    <FormControlLabel
                      value="Muttersprache"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="Muttersprache"
                    />
                  </RadioGroup>
                  <p className="form_label">
                    Wie gut sind Deine deutschen Sprachkenntnisse beim Schreiben?
                  </p>
                  <RadioGroup
                    row
                    required
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="writing"
                    value={formData.writing}
                    onChange={handleSeekersInputChange}
                  >
                    <FormControlLabel
                      value="A1"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="A1"
                    />
                    <FormControlLabel
                      value="A2"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="A2"
                    />
                    <FormControlLabel
                      value="B1"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="B1"
                    />
                    <FormControlLabel
                      value="B2"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="B2"
                    />
                    <FormControlLabel
                      value="C1"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="C1"
                    />
                    <FormControlLabel
                      value="C2"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="C2"
                    />
                    <FormControlLabel
                      value="Muttersprache"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label="Muttersprache"
                    />
                  </RadioGroup>

                  <RadioGroup
                  className="daten-check"
                    row
                    required
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="consent"
                    value={formData.consent}
                    onChange={handleSeekersInputChange}
                  >
                    <FormControlLabel
                      value="Ja"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label={<span> Ich bin davon einverstanden, dass meine persönlichen Daten an <a href="/partnernetzwerk">unser Partnernetzwerk</a>, Arbeitgeber und Auftraggeber weitergeleitet werden können ausschließlich für die Beantwortung des Anliegens bzw. zu Zwecken der Jobvermittlung. </span>}
                    />
                  </RadioGroup>
                  <div className="captcha_container">
                <ReCAPTCHA
                  sitekey="6LfbQJEpAAAAAP2uTj5sdv5bYMmex1NMqY7pbsjy"
                  hl="de"
                />
                </div>
                  {load && <p>Loading...</p>}
                  {successMsg && <p style={{ color: "green" }}>{successMsg}</p>}
                  {errorMsg && <p style={{ color: "red" }}>{errorMsg}</p>}
                  <button
                    className="submit-form-btn"
                    variant="contained"
                    type="submit"
                  >
                    ANFRAGE ABSCHICKEN
                  </button>
                </form>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
