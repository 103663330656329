import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TextField, Box } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

export const Reisen = () => {
  const [formData, setFormData] = useState({
    reisen: "",
    company: "",
    name: "",
    help: "",
    phone: "",
    email: "",
    consent: "",
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      // Basic form validation
      if (
        !formData.company ||
        !formData.name ||
        !formData.help ||
        !formData.phone ||
        !formData.email
      ) {
        setErrorMessage("Please fill out all required fields.");
        return;
      }

      const htmlBody = `
      <p><b>${formData.reisen}</b></p>
      <p><b>Unternehmen:</b> ${formData.company}</p>
      <p><b>Ich heiße:</b> ${formData.name}</p>
      <p><b>Ich brauche:</b> ${formData.help}</p>
      <p><b>Telefon:</b> ${formData.phone}</p>
      <p><b>Email:</b> ${formData.email}</p>
      <p><b>Consent:</b> ${formData.consent}</p>
    `;
      const response = await fetch("/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: "anfragen@mezu.de",
          subject: "Reisen",
          html: htmlBody,
        }),
      });

      if (response.ok) {
        setSuccessMessage("Email sent successfully!");
        setFormData({
          reisen: "",
          company: "",
          name: "",
          help: "",
          phone: "",
          email: "",
          consent: "",
        });
        window.grecaptcha.reset();
      } else {
        setErrorMessage("Failed to send email. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Error sending email. Please try again.");
      console.error("Error sending email:", error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <header id="header">
        <div className="intro-voyage">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intern-intro-text">
                  <h1>
                    Reisen
                    <span></span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="container">
        <div className="col-md-12">
          <div className="row">
            <div>
              <div id="about">
                <div className="container">
                  <div className="row">
                    <div className="col-xs-12 col-md-6">
                      {" "}
                      <img
                        src="img/reisen2.jpg"
                        className="img-responsive"
                        alt=""
                      />{" "}
                    </div>
                    <div className="col-xs-12 col-md-6">
                      <div className="about-text">
                        <h2>Für Unternehmen</h2>
                        <p>
                          Wir haben jahrelange Erfahrung in der Führung von
                          Onlinereisebüros und sind aktuell spezialisiert auf
                          Firmenreisedienst und Gruppenreisen. Wir übernehmen
                          die Buchhaltung für Reisebüros.
                        </p>
                        <h5>KFF Tourismus GmbH</h5>

                        <div style={{paddingBottom:"10px"}} className="col-xs-12 col-md-12 logo-resp ">
                          <a
                            href="https://www.gruppen-fliegen.de/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="img/gruppen-fliegen.svg"
                              className="img-responsive"
                              alt=""
                              style={{ boxShadow: "none", width:"auto" }}
                            />
                          </a>
                        </div>

                        <h5>Gruppenreisen Touristik GmbH</h5>
                        <div style={{paddingBottom:"10px"}} className="col-xs-12 col-md-6 logo-resp ">
                          <a
                            href="https://www.vereinsreisen.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="img/vereinsreisen.png"
                              className="img-responsive"
                              alt=""
                              style={{ boxShadow: "none", width:"auto" }}
                            />
                          </a>
                        </div>

                        <div style={{paddingBottom:"10px"}} className="col-xs-12 col-md-6 logo-resp ">
                          <a
                            href="https://www.gruppenfluege.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="img/gruppenfluege.png"
                              className="img-responsive"
                              alt=""
                              style={{ boxShadow: "none", width:"auto" }}
                            />
                          </a>
                        </div>
                        <h5>Swinging Agents France S.A.S.</h5>

                        <div style={{paddingBottom:"10px"}} className="col-xs-12 col-md-12 logo-resp ">
                          <a
                            href="https://voyages-experts.fr/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="img/gruppen-fliegen.svg"
                              className="img-responsive"
                              alt=""
                              style={{ boxShadow: "none", width:"auto" }}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="about">
                <div className="container">
                  <div className="row">
                    <div className="col-xs-12 col-md-6">
                      <div className="about-text">
                        <h2>Für Reisende</h2>
                        
                        <p>
                        Bitte besuchen Sie uns auf unseren deutschen Websites unter</p>
                        <div className="list-style">
                      <ul>
                        <li><a
                            href="https://www.gruppen-fliegen.de/"
                            target="_blank"
                            rel="noreferrer"
                          >{" "}
                            gruppen-fliegen.de
                          </a>
                          </li>
                          <li><a
                            href="https://www.gruppenfluege.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            gruppenflüge.com{" "}
                          </a></li>
                          <li><a
                            href="https://www.vereinsreisen.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            vereinsreisen.com{" "}
                          </a></li>
                      </ul>
                    </div>
                          <p>oder auf unserer französischen Website</p>
                        <div className="list-style">
                          <ul>
                            <li>
                            <a
                            href="https://voyages-experts.fr/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            voyages-experts.fr
                          </a>
                            </li>
                          </ul>
                          
                        </div>
                       <p>Gerne stehen wir Ihnen auf diesen Websites telefonisch und per Email für Ihre Buchungswünsche zur Verfügung.</p>
                      </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                      {" "}
                      <img
                        src="img/reisen1.jpg"
                        className="img-responsive"
                        alt=""
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Box className="form-box" sx={{ p: 2 }}>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="reisen"
                    value={formData.reisen}
                    onChange={handleInputChange}
                    style={{ fontSize: "15px" }}
                    label=""
                  >
                    <MenuItem style={{ fontSize: "15px" }} value="Buchhaltung">
                      Buchhaltung
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: "15px" }}
                      value="Firmenreisedienst"
                    >
                      Firmenreisedienst
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: "15px" }}
                      value="Gruppenreisen"
                    >
                      Gruppenreisen
                    </MenuItem>
                  </Select>
                </FormControl>
                <p className="form_label">Wie heißt Ihr Unternehmen?</p>
                <TextField
                  inputProps={{ style: { fontSize: 15, padding: 10 } }}
                  fullWidth
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                />
                <p className="form_label">Wie heißen Sie?</p>
                <TextField
                  fullWidth
                  inputProps={{ style: { fontSize: 15, padding: 10 } }}
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                <p className="form_label">Wie können wir Ihnen helfen?</p>
                <TextField
                  fullWidth
                  inputProps={{ style: { fontSize: 15, padding: 10 } }}
                  name="help"
                  value={formData.help}
                  onChange={handleInputChange}
                />
                <p className="form_label">Wie können wir Sie telefonisch erreichen?</p>
                <TextField
                  fullWidth
                  inputProps={{ style: { fontSize: 15, padding: 10 } }}
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
                <p className="form_label">Wie können wir Sie per Mail erreichen?</p>
                <TextField
                  fullWidth
                  type={"email"}
                  inputProps={{ style: { fontSize: 15, padding: 10 } }}
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />

                <RadioGroup
                className="daten-check"
                  row
                  required
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="consent"
                  value={formData.consent}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="Ja"
                    InputLabelProps={{ style: { fontSize: 15 } }}
                    control={<Radio />}
                    label={<span>Ich bin davon einverstanden, dass meine persönlichen Daten auch an <a href="/partnernetzwerk">unser Partnernetzwerk</a>, Arbeitgeber und Auftraggeber weitergeleitet werden können ausschließlich für die Beantwortung des Anliegens bzw. zu Zwecken der Auftragsvermittlung. Jegliche Verwendung meiner persönlichen Daten durch Dritte für sonstige Marketingzwecke ist ausgeschlossen.</span>} 
                  />
                </RadioGroup>
                <div className="captcha_container">
                <ReCAPTCHA
                  sitekey="6LfbQJEpAAAAAP2uTj5sdv5bYMmex1NMqY7pbsjy"
                  hl="de"
                />
                </div>
                {loading && <p>Loading...</p>}
                {successMessage && (
                  <p style={{ color: "green" }}>{successMessage}</p>
                )}
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                <button
                  className="submit-form-btn"
                  variant="contained"
                  type="submit"
                >
                  ANFRAGE ABSCHICKEN
                </button>
              </form>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};
