import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigation } from "./components/NavBar";
import { Contact } from "./components/Footer";
import JsonData from "./data/data.json";
import "./App.css";
import { Home } from "./components/Home";
import { Buchhaltung } from "./components/Buchhaltung";
import { Contactcenter } from "./components/Contactcenter";
import { Firmengruendung } from "./components/Firmengruendung";
import { It } from "./components/It";
import { Jobs } from "./components/Jobs";
import { Marktforschung } from "./components/Marktforschung";
import { Personalvermittlung } from "./components/Personalvermittlung";
import { Reisen } from "./components/Reisen";
import { Kontakt } from "./components/Kontakt";
import { Impressum } from "./components/Impressum";
import { Datenschutz } from "./components/Datenschutz";
import ScrollToTop from "./data/ScrollTop";
import { Partnernetzwerk } from "./components/Partnernetzwerk";

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (

    <Router>
    <div>
    <ScrollToTop />
      <Navigation />
      <Routes>
        
          <Route path="/" exact element={<Home data={landingPageData}/>} />
          <Route path="/buchhaltung" element={<Buchhaltung data={landingPageData} />} />
          <Route path="/contactcenter" element={<Contactcenter data={landingPageData} />} />
          <Route path="/firmengruendung" element={<Firmengruendung data={landingPageData}/>} />
          <Route path="/it" element={<It data={landingPageData} />} />
          <Route path="/jobs" element={<Jobs data={landingPageData} />} />
          <Route path="/marktforschung" element={<Marktforschung data={landingPageData} />} />
          <Route path="/personalvermittlung" element={<Personalvermittlung data={landingPageData} />} />
          <Route path="/reisen" element={<Reisen data={landingPageData} />} />
          <Route path="/kontakt" element ={<Kontakt />}/>
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="/partnernetzwerk" element={<Partnernetzwerk />} />
        </Routes>
      {/* <Home data={landingPageData} /> */}
      <Contact data={landingPageData.Contact} />
    </div>
    </Router>
  );
};

export default App;
