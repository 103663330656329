import React from "react";

export const Impressum = () => {
  return (
    <div>
      <header id="header">
        <div className="intro-impressum">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intern-intro-text">
                  <h1>
                  Impressum
                    <span></span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="container">
        <div className="row">
          <b>Angaben gemäß § 5 TMG</b>
          <p>
            mezu GmbH
            <br />
            Domplatz 40
            <br />
            48143 Münster
            <br />
            Handelsregister: HRB 21362
            <br />
            Registergericht: Amtsgericht Münster
          </p>
          <b>Vertreten durch:</b>
          <p>Jörn Eble</p>
          <b>Kontakt</b>
          <p>
            Telefon: +49 30 629 30 20 20
            <br />
            Fax: +49 30 629 30 20 11
            <br />
            Email: kontakt@mezu.de
          </p>
          <b>Postadresse</b>
          <p>
            Domplatz 40
            <br />
            48143 Münster
          </p>
          <b>Umsatzsteuer-ID</b>
          <p>
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
            <br />
            DE307425516
          </p>
          <b>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</b>
          <p>
            mezu GmbH
            <br />
            Domplatz 40
            <br />
            48143 Münster
          </p>
          <b>EU-Streitschlichtung</b>
          <p>
            Die Europäische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:
            <br />
            https://ec.europa.eu/consumers/odr.
            <br />
            Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>
          <b>Verbraucherstreitbeilegung/Universalschlichtungsstelle</b>
          <p>
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
          <b>Haftung für Inhalte</b>
          <p>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine
            rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung
            oder Sperrung der Nutzung von Informationen nach den allgemeinen
            Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
            jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
            Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
            Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </p>
          <b>Haftung für Links</b>
          <p>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren
            Inhalte wir keinen Einfluss haben.
            <br />
            Deshalb können wir für diese fremden Inhalte auch keine Gewähr
            übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
            jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
            verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
            Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt
            der Verlinkung nicht erkennbar. Eine permanente inhaltliche
            Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
            Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
            Bekanntwerden von Rechtsverletzungen werden wir derartige Links
            umgehend entfernen.
          </p>
          <b>Urheberrecht</b>
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden wir derartige Inhalte umgehend entfernen.
            <br />
            Bildnachweise:
            <br />
            Alle verwendeten Fotos unterliegen der lizenzfreien Nutzung von
            Pixabay und Pexel, außer:
            <br />
            Profilfoto Jörn Eble ©Jörn Eble
            <br />
            Quelle:
            <br />
            https://www.e-recht24.de/impressum-generator.html
          </p>
        </div>
      </div>
    </div>
  );
};
