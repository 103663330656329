import React from "react";
import { Link } from "react-router-dom";
export const Home = (props) => {
  return (
    <div>
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <img src="img/mezu-textlogo-white.png" alt="mezu" className="mezu_logo_header"/>
                  <p>
                    {props.data.Header
                      ? props.data.Header.paragraph
                      : "Loading"}
                  </p>
                  
                  <Link className="btn btn-custom btn-lg page-scroll" to="/kontakt" >kontaktiere uns</Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <div id="features" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Features</h2>
          </div>
          <div className="row">
            {props.data.Features
              ? props.data.Features.map((d, i) => (
                  <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                    {" "}
                    <i className={d.icon}></i>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div> */}
      <div>
        <div id="about">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                {" "}
                <img
                  src="img/company.webp"
                  className="img-responsive"
                  alt=""
                />{" "}
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="about-text">
                  <h2>Für Unternehmen</h2>
                  <p>
                    Wir können Ihnen helfen, wenn Sie in Deutschland, im
                    Nearshore oder Offshore Personal suchen. Sie können in
                    Deutschland wählen zwischen Festangestellten oder
                    Personalüberlassung, Sie können im Ausland wählen zwischen
                    Freelancern, die im Homeoffice für Sie arbeiten oder
                    Mitarbeiterinnen und Mitarbeitern, die festangestellt in
                    unseren Büros für Sie arbeiten oder wir suchen Menschen, die
                    in ihr Team passen und nach Deutschland umziehen wollen.
                  </p>
                  <Link className="btn btn-custom btn-lg page-scroll" to="/personalvermittlung" >Weitere Informationen</Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="about">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <div className="about-text">
                  <h2>Für Arbeitssuchende</h2>
                  <p>
                    Wir und unsere Partnerunternehmen beschäftigen aktuell
                    Menschen in Argentinien, Brasilien, China, Deutschland,
                    Frankreich, Griechenland, Kosovo, Philippinen, Tunesien und
                    der Türkei.
                  </p>
                  <p>
                    Du suchst Arbeit? Gerne sind wir Dir bei der Jobsuche
                    behilflich.
                  </p>
                  <Link className="btn btn-custom btn-lg page-scroll" to="/jobs" >Weitere Informationen</Link>{" "}
                </div>
              </div>
              <div className="col-xs-12 col-md-6">
                {" "}
                <img
                  src="img/jobsearch.webp"
                  className="img-responsive"
                  alt=""
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>UNSER ANGEBOT</h2>
            <p>
            Wir sind spezialisiert auf verschiedene Bereiche.
            </p>
          </div>
          <div className="row">
            {props.data.Services1
              ? props.data.Services1.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-3">
                    {" "}
                    <Link to={d.path} ><i className={d.icon}></i></Link>
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
          <div className="row">
            {props.data.Services2
              ? props.data.Services2.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-3">
                    {" "}
                    <Link to={d.path} ><i className={d.icon}></i></Link>
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>

    </div>
  );
};
