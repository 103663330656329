
import React, {useState} from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TextField, Box } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
export const Buchhaltung = (props) => {
  const [formData, setFormData] = useState({
    company: '',
    name: '',
    help: '',
    phone: '',
    email: '',
    consent: '', 
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

 const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      // Basic form validation
      if (!formData.company || !formData.name || !formData.help || !formData.phone || !formData.email) {
        setErrorMessage('Please fill out all required fields.');
        return;
      }
      const htmlBody = `
      <p><b>Unternehmen:</b> ${formData.company}</p>
      <p><b>Ich heiße:</b> ${formData.name}</p>
      <p><b>Ich brauche:</b> ${formData.help}</p>
      <p><b>Telefon:</b> ${formData.phone}</p>
      <p><b>Email:</b> ${formData.email}</p>
      <p><b>Consent:</b> ${formData.consent}</p>
    `;
      const response = await fetch('/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: 'anfragen@mezu.de',
          subject: 'Buchhaltung',
          html: htmlBody,
        }),
      });

      if (response.ok) {
        setSuccessMessage('Email sent successfully!');
        setFormData({
          company: "",
          name: "",
          help: "",
          phone: "",
          email: "",
          consent: "",
        });
        window.grecaptcha.reset();
      } else {
        setErrorMessage('Failed to send email. Please try again.');
      }
    } catch (error) {
      setErrorMessage('Error sending email. Please try again.');
      console.error('Error sending email:', error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <header id="header">
        <div className="intro-compta">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intern-intro-text">
                  <h1>
                    Buchhaltung
                    <span></span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div>
        <div id="about">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                {" "}
                <img
                  src="img/compta2-min.jpg"
                  className="img-responsive"
                  alt=""
                />{" "}
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="about-text">
                  <h2>Buchhaltung</h2>
                  <p>
                    Wir können Ihnen helfen, wenn Sie in Deutschland, im
                    Nearshore oder Offshore Personal suchen. Sie können in
                    Deutschland wählen zwischen Festangestellten oder
                    Personalüberlassung, Sie können im Ausland wählen zwischen
                    Freelancern, die im Homeoffice für Sie arbeiten oder
                    Mitarbeiterinnen und Mitarbeitern, die festangestellt in
                    unseren Büros für Sie arbeiten oder wir suchen Menschen, die in Ihr Team passen und nach Deutschland umziehen wollen.
                  </p>
                  <p>
                    Wir haben Erfahrung im Bereich Datev, Lexware, SAP, XMID
                    (Reisebürobuchhaltung). Gerne erstellen wir Ihnen Ihre Lohn-
                    und Gehaltsabrechnung.
                  </p>
                  <p>
                    Bitte schildern Sie uns unverbindlich Ihren Bedarf, wir
                    melden uns umgehend bei Ihnen.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <Box className="form-box" sx={{ p: 2 }}>
                <form
                  onSubmit={handleSubmit}
                >
                  <p className="form_label">Wie heißt Ihr Unternehmen?</p>
                  <TextField
                  required
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    fullWidth
                    name="company"
                    value={formData.company}
          onChange={handleInputChange}
                  />
                  <p className="form_label">Wie heißen Sie?</p>
                  <TextField
                  required
                    fullWidth
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    name="name"
                    value={formData.name}
          onChange={handleInputChange}
                  />
                  <p className="form_label">Wie können wir Ihnen helfen?</p>
                  <TextField
                  required
                    fullWidth
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    name="help"
                    value={formData.help}
          onChange={handleInputChange}
                  />
                  <p className="form_label">Wie können wir Sie telefonisch erreichen?</p>
                  <TextField
                  required
                    fullWidth
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    name="phone"
                    value={formData.phone}
          onChange={handleInputChange}
                  />
                  <p className="form_label">Wie können wir Sie per Mail erreichen?</p>
                  <TextField
                  required
                    fullWidth
                    type={"email"}
                    inputProps={{ style: { fontSize: 15, padding: 10 } }}
                    name="email"
                    value={formData.email}
          onChange={handleInputChange}
                  />

                  <RadioGroup
                  className="daten-check"
                    row
                    required
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="consent"
                    value={formData.consent}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel
                      value="Ja"
                      InputLabelProps={{ style: { fontSize: 15 } }}
                      control={<Radio />}
                      label={<span>Ich bin davon einverstanden, dass meine persönlichen Daten auch an <a href="/partnernetzwerk">unser Partnernetzwerk</a>, Arbeitgeber und Auftraggeber weitergeleitet werden können ausschließlich für die Beantwortung des Anliegens bzw. zu Zwecken der Auftragsvermittlung. Jegliche Verwendung meiner persönlichen Daten durch Dritte für sonstige Marketingzwecke ist ausgeschlossen.</span>}
                    />
                  </RadioGroup>
                  <div className="captcha_container">
                <ReCAPTCHA
                  sitekey="6LfbQJEpAAAAAP2uTj5sdv5bYMmex1NMqY7pbsjy"
                  hl="de"
                />
                </div>
                  {loading && <p>Loading...</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                  <button
                    className="submit-form-btn"
                    type="submit"
                  >
                    ANFRAGE ABSCHICKEN
                  </button>
                </form>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
